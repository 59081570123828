<template>
  <div>
    <div
      class="app-page-spinner"
      v-if="findLoading"
      v-loading="findLoading"
    ></div>

    <app-project-create-form
      :isEditing="isEditing"
      :record="record"
      @submit="doSubmit"
      @setTeamUpdateFlag="setTeamUpdateFlag"
      :saveLoading="saveLoading"
      v-if="!findLoading"
      ref="appProjectCreateForm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectCreateForm from '@/modules/project-create/components/project-create-form';
import { EventBus } from '@/eventBus';
import CreateStatus from '@/shared/enums/createStatus';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { i18n } from '@/i18n';
import ProjectStepEnum from '@/shared/enums/projectStep';
const createStatus = CreateStatus.values;

export default {
  name: 'app-project-create-form-project-page',

  components: {
    [ProjectCreateForm.name]: ProjectCreateForm,
  },

  data() {
    return {
      projectStepEnum: ProjectStepEnum.values,
      isTeamUpdate: false,
      isDrty: false,
    };
  },

  computed: {
    ...mapGetters({
      record: 'projectCreate/form/record',
      findLoading: 'projectCreate/form/findLoading',
      saveLoading: 'projectCreate/form/saveLoading',
      pendingFiles: 'projectCreate/form/pendingFiles',
      status: 'projectCreate/form/status',
      isUpdateOfProjectTeam:
        'projectCreate/form/isUpdateOfProjectTeam',
      id: 'projectCreate/form/id',
      projectId: 'project/form/projectId',
      projectCreateId: 'project/form/projectCreateId',
      projectStatus: 'project/form/status',
    }),

    isEditing() {
      return !!this.projectCreateId;
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.projectCreateId);
    } else {
      await this.doNew();
    }
  },

  async beforeRouteLeave(to, from, next) {
    //if status is completed we skip the dialog check and navigate to next page
    const bla = this.status;
    if (bla === 'completed' || to.name.includes('error')) {
      next();
      return;
    }

    try {
      await this.$confirm(
        i18n('common.discardFormConfirm'),
        i18n('common.confirm'),
        {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
          closeOnClickModal: false,
          closeOnPressEscape: false,
        },
      );

      let response;
      if (this.pendingFiles.length > 0) {
        response = await FileUploader.removePendingFiles(
          this.pendingFiles,
        );
        if (response === 200) next();
      }

      next();
    } catch (error) {
      next(false);
    }
  },

  methods: {
    ...mapActions({
      doFind: 'projectCreate/form/doFind',
      doNew: 'projectCreate/form/doNew',
      doUpdate: 'projectCreate/form/doUpdate',
      doCreate: 'projectCreate/form/doCreate',
      doSetProjectCreateId: 'project/form/doSetProjectCreateId',
      doUpdateProject: 'project/form/doUpdate',
      doReloadProject: 'project/form/doReload',
      doUpdateProjectStep: 'project/form/doUpdateProjectStep',
    }),

    /*    setDrty(){
      this.isDrty = true;
    }, */

    navigateToProjectRelease() {
      EventBus.$emit('navigateToProjectRelease');
    },

    async setTeamUpdateFlag() {
      this.isTeamUpdate = true;
    },

    async doSubmit(payload) {
      if (this.isEditing) {
        const response = await this.doUpdate(payload);
        if (response && !this.isTeamUpdate) {
          if (
            payload.values.status === createStatus.completed &&
            this.projectStatus != 'CREATED/REVISION_REQUESTED'
          ) {
            /*  await this.doUpdateProjectStep(
              this.projectStepEnum.release,
            ); */

            //
            await this.navigateToProjectRelease();
          }
        } else {
          this.isTeamUpdate = false;
        }
        if (response && response.status === createStatus.completed) {
          await this.doReloadProject();
        }
      } else {
        // project create is created with the project on the server side
        const id = await this.doCreate(payload.values);
        await this.doSetProjectCreateId(id);
        await this.doUpdateProject();
      }
      if (this.$refs.appProjectCreateForm) {
        this.$refs.appProjectCreateForm.doUpdateModelFromRecord();
      }
    },
  },
};
</script>

<style scoped>
</style>
