<template>
  <div>
    <form-wizard
      :start-index="getWizardStartIndex"
      @on-complete="doSubmitFinish"
      color="#393939"
      error-color="#ff4949"
      step-size="xs"
      layout="hortzontal"
      ref="formWizard"
    >
      <h2 slot="title"></h2>
      <tab-content
        :before-change="validateTitle"
        icon="ti-user"
        title="Title"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formTitle"
          v-if="model"
          :disabled="isProjectClosed"
        >
          <!--  <el-form-item
            :label="fields.visibleExternallyEnum.label"
            :prop="fields.visibleExternallyEnum.name"
            :required="fields.visibleExternallyEnum.required"
            class="el-form-item--label-top"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <el-select
                placeholder
                v-model="model[fields.visibleExternallyEnum.name]"
                style="width: 100px;"
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.visibleExternallyEnum.options"
                ></el-option>
              </el-select>
            </el-col>
          </el-form-item> -->

          <el-form-item
            :label="fields.title.label"
            :prop="fields.title.name"
            :required="fields.title.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <el-input
                :disabled="isDisabled"
                :maxlength="fields.title.max"
                show-word-limit
                autofocus
                v-model="model[fields.title.name]"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 5 }"
              />
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <tab-content
        :before-change="validateDescription"
        icon="ti-user"
        title="Description"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formDescription"
          v-if="model"
          :disabled="isDisabled"
        >
          <el-form-item
            :label="fields.description.label"
            :prop="fields.description.name"
            :required="fields.description.required"
          >
            <el-col :lg="18" :md="16" :sm="24">
              <el-input
                :rows="4"
                :maxlength="fields.description.max"
                show-word-limit
                autofocus
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 5 }"
                v-model="model[fields.description.name]"
              />
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <tab-content
        :before-change="validateResearchFocus"
        icon="ti-user"
        title="Research focus"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formResearchFocus"
          v-if="model"
          :disabled="isDisabled"
        >
          <el-form-item>
            <el-col :lg="13" :md="16" :sm="24">
              <el-alert
                title="Note"
                :description="
                  getTranslation(
                    'entities.projectCreate.alert.researchFocus',
                  )
                "
                type="warning"
                :closable="false"
                show-icon
              >
              </el-alert>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.researchFocus.label"
            :prop="fields.researchFocus.name"
            :required="fields.researchFocus.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-research-focus-autocomplete-input
                :fetchFn="fields.researchFocus.fetchFn"
                :mapperFn="fields.researchFocus.mapperFn"
                :parentId="model.id"
                :showCreate="!modal"
                mode="multiple"
                v-model="model[fields.researchFocus.name]"
              ></app-research-focus-autocomplete-input>
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <tab-content
        :before-change="validateSubjectArea"
        icon="ti-user"
        title="Subject area"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formSubjectArea"
          v-if="model"
          :disabled="isDisabled"
        >
          <el-form-item
            :label="fields.subjectArea.label"
            :prop="fields.subjectArea.name"
            :required="fields.subjectArea.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-subject-area-autocomplete-input
                :fetchFn="fields.subjectArea.fetchFn"
                :mapperFn="fields.subjectArea.mapperFn"
                :parentId="model.id"
                :showCreate="!modal"
                mode="multiple"
                v-model="model[fields.subjectArea.name]"
              ></app-subject-area-autocomplete-input>
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <tab-content
        :before-change="validateFaculty"
        icon="ti-user"
        title="Faculty"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formFaculty"
          v-if="model"
          :disabled="isDisabled"
        >
          <el-form-item
            :label="fields.faculty.label"
            :prop="fields.faculty.name"
            :required="fields.faculty.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-faculty-autocomplete-input
                :fetchFn="fields.faculty.fetchFn"
                :mapperFn="fields.faculty.mapperFn"
                :showCreate="!modal"
                mode="multiple"
                v-model="model[fields.faculty.name]"
              ></app-faculty-autocomplete-input>
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <tab-content
        :before-change="validateProjectTeam"
        icon="el-icon-user"
        title="Project team"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formProjectTeam"
          v-if="model"
          :disabled="isProjectClosed"
        >
          <el-form-item>
            <el-col :lg="13" :md="16" :sm="24">
              <el-alert
                title="Note"
                :description="
                  getTranslation(
                    'entities.projectCreate.alert.projectTeam',
                  )
                "
                type="warning"
                :closable="false"
                show-icon
              >
              </el-alert>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.projectManager.label"
            :prop="fields.projectManager.name"
            :required="fields.projectManager.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-iam-user-autocomplete-input
                :fetchFn="fields.projectManager.fetchFn"
                :mapperFn="fields.projectManager.mapperFn"
                :showCreate="!modal"
                mode="multiple"
                v-model="model[fields.projectManager.name]"
              ></app-iam-user-autocomplete-input>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.teamMember.label"
            :prop="fields.teamMember.name"
            :required="fields.teamMember.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-iam-user-autocomplete-input
                :fetchFn="fields.teamMember.fetchFn"
                :mapperFn="fields.teamMember.mapperFn"
                :showCreate="!modal"
                :clearable="false"
                mode="multiple"
                v-model="model[fields.teamMember.name]"
              ></app-iam-user-autocomplete-input>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.timeSheet.label"
            :prop="fields.timeSheet.name"
            :required="fields.timeSheet.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-file-upload
                :max="fields.timeSheet.max"
                :storage="fields.timeSheet.storage"
                :formats="fields.timeSheet.formats"
                :permissions="fields.timeSheet.permissions"
                v-model="model[fields.timeSheet.name]"
              ></app-file-upload>
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <tab-content
        :before-change="validateCooperationPartner"
        icon="ti-user"
        title="Cooperation partner"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formCooperationPartner"
          v-if="model"
          :disabled="isProjectClosed"
        >
          <el-form-item>
            <el-col :lg="13" :md="16" :sm="24">
              <el-alert
                title="Note"
                description="Removal of Cooperation partners is not possible (only
                admins can)."
                type="warning"
                :closable="false"
                show-icon
              >
              </el-alert>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.cooperationPartnerEnum.label"
            :prop="fields.cooperationPartnerEnum.name"
            :required="fields.cooperationPartnerEnum.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <el-select
                placeholder
                v-model="model[fields.cooperationPartnerEnum.name]"
                @change="onChangeCooperationPartnerEnum"
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.cooperationPartnerEnum
                    .options"
                ></el-option>
              </el-select>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.cooperationPartner.label"
            :prop="fields.cooperationPartner.name"
            :required="fields.cooperationPartner.required"
            v-if="model[fields.cooperationPartnerEnum.name] === 'yes'"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-cooperation-partner-autocomplete-input
                :fetchFn="fields.cooperationPartner.fetchFn"
                :mapperFn="fields.cooperationPartner.mapperFn"
                :showCreate="!modal"
                mode="multiple"
                v-model="model[fields.cooperationPartner.name]"
              ></app-cooperation-partner-autocomplete-input>
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <tab-content
        :before-change="validateCostsPlan"
        icon="ti-user"
        title="Costs plan"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formCostsPlan"
          v-if="model"
          :disabled="isDisabled"
        >
          <el-form-item
            :label="fields.plannedAmount.label"
            :prop="fields.plannedAmount.name"
            :required="fields.plannedAmount.required"
          >
            <el-col :lg="3" :md="6" :sm="8">
              <el-input-number
                ref="focus"
                :controls="false"
                :precision="fields.plannedAmount.scale"
                v-model="model[fields.plannedAmount.name]"
              ></el-input-number>
            </el-col>
          </el-form-item>

          <!-- Template to download for Costs Plan template-->
          <app-template-view-file-page
            templateType="costs"
          ></app-template-view-file-page>

          <el-form-item
            :label="fields.costsPlan.label"
            :prop="fields.costsPlan.name"
            :required="fields.costsPlan.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-file-upload
                :max="fields.costsPlan.max"
                :storage="fields.costsPlan.storage"
                :formats="fields.costsPlan.formats"
                :permissions="fields.costsPlan.permissions"
                v-model="model[fields.costsPlan.name]"
              ></app-file-upload>
            </el-col>
          </el-form-item>

          <!-- Template to download for Project Description template-->
          <app-template-view-file-page
            templateType="descriptionThirdPartyFunding"
          ></app-template-view-file-page>

          <el-form-item
            :label="fields.descriptionThirdPartyFunding.label"
            :prop="fields.descriptionThirdPartyFunding.name"
            :required="fields.descriptionThirdPartyFunding.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-file-upload
                :max="fields.descriptionThirdPartyFunding.max"
                :storage="fields.descriptionThirdPartyFunding.storage"
                :formats="fields.descriptionThirdPartyFunding.formats"
                :permissions="
                  fields.descriptionThirdPartyFunding.permissions
                "
                v-model="
                  model[fields.descriptionThirdPartyFunding.name]
                "
              ></app-file-upload>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.financialGuideline.label"
            :prop="fields.financialGuideline.name"
            :required="fields.financialGuideline.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-file-upload
                :max="fields.financialGuideline.max"
                :storage="fields.financialGuideline.storage"
                :formats="fields.financialGuideline.formats"
                :permissions="fields.financialGuideline.permissions"
                v-model="model[fields.financialGuideline.name]"
              ></app-file-upload>
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <tab-content
        :before-change="validateFundingAgency"
        icon="ti-user"
        title="Funding agency"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formFundingAgency"
          v-if="model"
          :disabled="isProjectClosed"
        >
          <el-form-item
            :label="fields.fundingAgencyEnum.label"
            :prop="fields.fundingAgencyEnum.name"
            :required="fields.fundingAgencyEnum.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <el-select
                placeholder
                v-model="model[fields.fundingAgencyEnum.name]"
                @change="onChangeFundingAgencyEnum"
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.fundingAgencyEnum.options"
                ></el-option>
              </el-select>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.fundingAgency.label"
            :prop="fields.fundingAgency.name"
            :required="fields.fundingAgency.required"
            v-if="model[fields.fundingAgencyEnum.name] === 'yes'"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-funding-agency-autocomplete-input
                :fetchFn="fields.fundingAgency.fetchFn"
                :mapperFn="fields.fundingAgency.mapperFn"
                :showCreate="!modal"
                mode="multiple"
                v-model="model[fields.fundingAgency.name]"
              ></app-funding-agency-autocomplete-input>
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <tab-content
        :before-change="validateFundingAgency"
        icon="ti-user"
        title="Duration"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formProjectDuration"
          v-if="model"
          :disabled="isDisabled"
        >
          <el-form-item
            :label="fields.projectDuration.label"
            :prop="fields.projectDuration.name"
            :required="fields.projectDuration.required"
          >
            <el-col :lg="3" :md="6" :sm="8">
              <el-input-number
                autofocus
                :controls="false"
                :precision="0"
                v-model="model[fields.projectDuration.name]"
              ></el-input-number>
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <!-- Wizard Buttons -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0"
            @click.native="props.prevTab()"
            :style="props.fillButtonStyle"
            :disabled="saveLoading"
            ><app-i18n code="common.previous"></app-i18n
          ></wizard-button>
        </div>
        <div class="wizard-footer-right">
          <div class="wizard-btn">
            <wizard-button
              :disabled="saveLoading"
              v-if="showSaveBtn"
              icon="el-icon-fa-floppy-o"
              @click.native="doSubmit"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              ><app-i18n code="common.save"></app-i18n
            ></wizard-button>
          </div>
          <div class="wizard-btn">
            <wizard-button
              :disabled="saveLoading"
              v-if="!props.isLastStep"
              @click.native="
                props.nextTab();
                doSubmit();
              "
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              ><app-i18n code="common.next"></app-i18n
            ></wizard-button>
            <wizard-button
              :disabled="saveLoading"
              @click.native="doSubmitFinish()"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              v-if="showFinishBtn && !isDisabled"
              ><app-i18n code="common.finish"></app-i18n
            ></wizard-button>
          </div>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ProjectCreateModel } from '@/modules/project-create/project-create-model';
import CostsPlanTemplate from '@/modules/template/components/template-view-file-page';
import CreateStatus from '@/shared/enums/createStatus';
import { i18n } from '@/i18n';

const statusEnum = CreateStatus.values;
const { fields } = ProjectCreateModel;
const formSchema = new FormSchema([
  fields.id,
  fields.visibleExternallyEnum,
  fields.title,
  fields.description,
  fields.researchFocus,
  fields.subjectArea,
  fields.faculty,
  fields.projectTeam,
  fields.teamMember,
  fields.projectManager,
  fields.cooperationPartnerEnum,
  fields.cooperationPartner,
  fields.plannedAmount,
  fields.timeSheet,
  fields.costsPlan,
  fields.descriptionThirdPartyFunding,
  fields.financialGuideline,
  fields.fundingAgencyEnum,
  fields.fundingAgency,
  fields.projectDuration,
  fields.status,
]);

export default {
  name: 'app-project-create-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  components: {
    [CostsPlanTemplate.name]: CostsPlanTemplate,
  },

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      isMounted: false,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  mounted() {
    this.isMounted = true;
  },

  watch: {
    record: function() {
      this.doUpdateModel();
    },
    model: {
      deep: true,
      handler: function() {
        this.$store.dispatch(
          'projectCreate/form/doSetFormModel',
          this.model,
        );
      },
    },
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      projectStatus: 'project/form/status',
      elementUiDateFormat: 'layout/elementUiDateFormat',
    }),

    fields() {
      return fields;
    },

    isProjectClosed() {
      return (
        this.projectStatus === 'CLOSED' ||
        this.projectStatus === 'COMPLETED'
      );
    },

    isDisabled() {
      if (this.status && this.status === statusEnum.completed) {
        return (
          this.projectStatus === 'CREATED/REQUESTED_FOR_RELEASE' ||
          this.projectStatus === 'CREATED/PROJECT_RELEASE_OPEN' ||
          this.projectStatus === 'RUNNING' ||
          this.projectStatus === 'CLOSED' ||
          this.projectStatus === 'COMPLETED'
        );
      }
      return false;
    },

    status() {
      return this.model.status;
    },

    isFinished() {
      return this.status === statusEnum.completed;
    },

    isLastWizardStep() {
      if (!this.isMounted) return;
      return this.$refs.formWizard.isLastStep;
    },

    showFinishBtn() {
      return (
        this.status === statusEnum.duration && this.isLastWizardStep
      );
    },

    showSaveBtn() {
      return this.isMounted
        ? !(
            this.isDisabled &&
            this.activeTabIndex != 0 &&
            this.activeTabIndex != 5 &&
            this.activeTabIndex != 6 &&
            this.activeTabIndex != 8
          ) || this.isProjectClosed
        : false;
    },

    getWizardStartIndex() {
      switch (this.status) {
        case statusEnum.title:
          return 0;
        case statusEnum.description:
          return 1;
        case statusEnum.researchFocus:
          return 2;
        case statusEnum.subjectArea:
          return 3;
        case statusEnum.faculty:
          return 4;
        case statusEnum.projectTeam:
          return 5;
        case statusEnum.cooperationPartner:
          return 6;
        case statusEnum.costsPlan:
          return 7;
        case statusEnum.fundingAgency:
          return 8;
        case statusEnum.duration:
        case statusEnum.completed:
          return 9;
        default:
          return 0;
      }
    },

    activeTabIndex() {
      return this.$refs.formWizard.activeTabIndex;
    },
  },

  methods: {
    getFieldLength(value) {
      return value.max;
    },

    getTranslation(value) {
      return i18n(value);
    },

    onChangeCooperationPartnerEnum(value) {
      if (value === 'no') {
        if (this.model.cooperationPartner.length >= 1) {
          this.model.cooperationPartner = [];
        }
      }
    },

    onChangeFundingAgencyEnum(value) {
      if (value === 'no') {
        if (this.model.fundingAgency.length >= 1) {
          this.model.fundingAgency = [];
        }
      }
    },

    getButtonTranslation(val) {
      return val ? i18n('common.finish') : i18n('common.next');
    },

    doUpdateModel() {
      this.model = formSchema.initialValues(this.record || {});
    },

    async doEmit() {
      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },

    async doSubmit() {
      //status is completed so we don't have anything to do
      if (
        (this.isDisabled &&
          this.status === statusEnum.completed &&
          this.activeTabIndex === 0) ||
        (this.isDisabled &&
          this.status === statusEnum.completed &&
          this.activeTabIndex === 5) ||
        (this.isDisabled &&
          this.status === statusEnum.completed &&
          this.activeTabIndex === 6) ||
        (this.isDisabled &&
          this.status === statusEnum.completed &&
          this.activeTabIndex === 8)
      ) {
        await this.validateProjectTeam();
        this.validateCooperationPartnerEnum();
        this.validateFundingAgencyEnum();
        this.$emit('setTeamUpdateFlag', true);
        return await this.doEmit();
      }
      if (this.status === statusEnum.completed && this.isDisabled) {
        return;
      }

      switch (this.activeTabIndex) {
        case 0:
          await this.validateTitle();
          await this.setStatus(statusEnum.title);
          break;
        case 1:
          await this.validateDescription();
          await this.setStatus(statusEnum.description);
          break;
        case 2:
          await this.validateResearchFocus();
          await this.setStatus(statusEnum.researchFocus);
          break;
        case 3:
          await this.validateSubjectArea();
          await this.setStatus(statusEnum.subjectArea);
          break;
        case 4:
          await this.validateFaculty();
          await this.setStatus(statusEnum.faculty);
          break;
        case 5:
          await this.validateProjectTeam();
          await this.setStatus(statusEnum.projectTeam);
          break;
        case 6:
          await this.validateCooperationPartner();
          //this.validateCooperationPartnerEnum();
          await this.setStatus(statusEnum.cooperationPartner);
          break;
        case 7:
          await this.validateCostsPlan();
          await this.setStatus(statusEnum.costsPlan);
          break;
        case 8:
          await this.validateFundingAgency();
          //this.validateFundingAgencyEnum();
          await this.setStatus(statusEnum.fundingAgency);
          break;
        case 9:
          await this.validateProjectDuration();
          await this.setStatus(statusEnum.duration);
          break;
        default:
          break;
      }

      return this.doEmit();
    },

    async doSubmitFinish() {
      if (!(this.status === statusEnum.completed)) {
        await this.setStatus(statusEnum.completed);
        return this.doEmit();
      }
    },

    validateFundingAgencyEnum() {
      if (
        this.model.fundingAgencyEnum === 'no' &&
        this.model.fundingAgency.length >= 1
      ) {
        this.model.fundingAgency = [];
      }
    },

    validateCooperationPartnerEnum() {
      if (
        this.model.cooperationPartnerEnum === 'no' &&
        this.model.cooperationPartner.length >= 1
      ) {
        this.model.cooperationPartner = [];
      }
    },

    async setStatus(value) {
      if (this.model.status === statusEnum.completed) {
        return;
      }
      this.model.status = value;
    },

    async validateTitle() {
      return this.$refs.formTitle.validate();
    },

    async validateDescription() {
      return this.$refs.formDescription.validate();
    },

    async validateResearchFocus() {
      return this.$refs.formResearchFocus.validate();
    },
    async validateSubjectArea() {
      return this.$refs.formSubjectArea.validate();
    },

    async validateFaculty() {
      return this.$refs.formFaculty.validate();
    },

    async validateProjectTeam() {
      return this.$refs.formProjectTeam.validate();
    },

    async validateCooperationPartner() {
      return this.$refs.formCooperationPartner.validate();
    },

    async validateCostsPlan() {
      return this.$refs.formCostsPlan.validate();
    },

    async validateFundingAgency() {
      return this.$refs.formFundingAgency.validate();
    },
    async validateProjectDuration() {
      return this.$refs.formProjectDuration.validate();
    },

    doUpdateModelFromRecord() {
      this.model = formSchema.initialValues(this.record || {});
    },
  },
};
</script>

<style scoped>
.wizard-btn {
  padding: 0px, 2px;
}
</style>
