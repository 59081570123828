/**
 * List of project create statuses.
 */
class CreateStatus {
    static get values() {
      return { 
        start: 'start',
        title: 'title',
        description: 'description',
        researchFocus: 'researchFocus',
        subjectArea: 'subjectArea',
        faculty: 'faculty',
        projectTeam: 'projectTeam',
        cooperationPartner: 'cooperationPartner',
        costsPlan: 'costsPlan',
        fundingAgency: 'fundingAgency',
        duration: 'duration',
        completed: 'completed',
      };
    }
  }
  
  module.exports = CreateStatus;
